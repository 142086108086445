import { Link } from "gatsby"
import React from "react"
import { Column, Container, Row } from "../components/Grid"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import OutboundLink from "../components/OutboundLink"
import Section from "../components/Section"
import SEO from "../components/Seo"
// import ThreeColumnCallout from "../components/ThreeColumnCallout"
// import MissionImage from "../images/theme/placeholder-image.png"
// import SubFooterImage from "../images/theme/placeholder-image.png"
// import DiscoveryEducationLogo from "../images/global/img-de-logo.png"
// import Subscribe from "../components/Subscribe";
import Image from "../components/Image"
import deLogo from "../images/global/img-de-logo.png"
import DoveLogo from "../images/theme/logo-dove-horizontal.svg"
import FooterCallout from "../partials/footerCallout"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    {/***************  HERO ***************/}
    <Section className="hero hero__about">
      <Hero
        title={
          <>
            <span className={"header-bar--yellow"}>About</span>
            <span className={"header-bar--yellow"}>the</span>
            <span className={"header-bar--yellow"}>Program</span>
          </>
        }
        body=""
        image="img-about-hero-circle.png"
      />
    </Section>

    {/***************  MISSION CALLOUT ***************/}
    <Section className="mission">
      <Container>
        <Row>
          <Column span={10}>
            <p>
              The Dove Self-Esteem Project and Discovery Education have
              partnered to bring Amazing Me to teachers nationwide. Amazing Me
              is designed to provide standards-aligned, no-cost, easy-to-use
              resources for educators and families to build life-long confidence
              and self-esteem in their students. With high self-esteem and body
              confidence, kids are more likely to reach their full potential in
              life.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  MISSION STATEMENT CALLOUT ***************/}
    <Section className="about__mission about__colcallout midgray-background">
      <Container>
        <Row>
          <Column span={5}>
            <Image filename={"img-about-mission.png"} />
          </Column>
          <Column span={7}>
            <h2>Our Mission</h2>
            <p>
              Provide students in grades 4&mdash;5 with the healthy skills and
              habits to reach their full potential. Amazing Me uses engaging
              videos, classroom lesson plans, and at-home support to teach
              students how to build self-esteem, power positive body image, and
              practice empathy and compassion for their peers.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  SUPPORTING PARTNERS CALLOUT ***************/}
    <Section className="partners">
      <Container>
        <h2>Supporting Partners</h2>
        {/*<Row className={'sponsor'}>*/}
        {/*  <Column span={3} className={'sponsor__logo sponsor__logo--unilever'}>*/}
        {/*    <Image filename={'placeholder-image.png'}/>*/}
        {/*  </Column>*/}
        {/*  <Column span={9}>*/}
        {/*    <h3>Unilever</h3>*/}
        {/*    <p>Curabitur rutrum a nunc at porttitor. Vivamus sollicitudin sollicitudin mi, in tempor velit molestie sed. Suspendisse interdum posuere enim, sed pulvinar enim laoreet at. Learn more about loriem ipsum dolor. Curabitur rutrum a nunc at porttitor. Vivamus sollicitudin sollicitudin mi.</p>*/}
        {/*    <OutboundLink to={'https://www.unilever.com/'} >*/}
        {/*      Link*/}
        {/*    </OutboundLink>*/}
        {/*  </Column>*/}
        {/*</Row>*/}
        <Row className={"sponsor"}>
          <Column span={2} className={" sponsor__logo sponsor__logo--dove"}>
            <img src={DoveLogo} alt={"Dove Self Esteem Project"} />
          </Column>
          <Column span={9} className={""}>
            <h3>Dove Self-Esteem Project</h3>
            <p>
              The Dove Self-Esteem Project was created from a vision where
              beauty is a source of confidence, not anxiety. We've reached over
              69 million young people, with a commitment to reach 250 million by
              2030. Our mission is to ensure that the next generation grows up
              enjoying a positive relationship with the way they look—helping
              young people raise their self-esteem and realize their full
              potential. We’ve partnered with leading experts in the fields of
              psychology, health, and body image to create a program of
              evidence-based resources, including parenting advice, to help
              young people form healthy friendships, overcome body image issues,
              and be their best selves.
            </p>
            <OutboundLink
              to={
                "https://www.dove.com/us/en/dove-self-esteem-project/our-mission.html"
              }
            >
              Learn More
            </OutboundLink>
          </Column>
        </Row>
        <Row className={"sponsor"}>
          <Column span={2} className={"sponsor__logo sponsor__logo--de"}>
            <img src={deLogo} alt={"Discovery Education"} />
          </Column>
          <Column span={9}>
            <h3>Discovery Education</h3>
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in nearly 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners. Explore the future of
              education at{" "}
              <OutboundLink to={"https://discoveryeducation.com"}>
                www.discoveryeducation.com.
              </OutboundLink>
            </p>
            <OutboundLink to={"https://www.discoveryeducation.com"}>
              Learn More
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
    <Section className={"squiggle"}>
      <Container>
        <h3>Explore Additional Resources</h3>
        <Row>
          <Column span={5} className={"is-offset-1 resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-resources.png"}
            />
            <h4 className={"bold-text"}>Classroom Resources</h4>
            <p>
              Help your students realize their full potential with NEW classroom
              resources.
            </p>
            <Link to={"/classroom-resources"}>Learn More</Link>
          </Column>
          <Column span={5} className={"resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-family.png"}
            />
            <h4 className={"bold-text"}>Family Resources</h4>
            <p>
              Encourage body positivity and self-esteem at home with these
              important family resources.
            </p>
            <Link to={"/family-resources"}>Learn More</Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className="body-confidence-fact body-confidence-fact--about">
      <Container>
        <Row>
          <Column span={7}>
            <p>
              When kids are confident and secure about who they are, they’re
              more likely to have a growth mindset. That means they can motivate
              themselves to take on new challenges and cope with and learn from
              mistakes.
            </p>

            <p>
              &mdash;<em>Understood.org</em>
            </p>
          </Column>
          <Column
            span={4}
            className={"is-offset-1 body-confidence-fact__container"}
          >
            <div className={"body-confidence-fact__container__image"}>
              <Image filename={"img-subfooter-5.png"} className={""} />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  FOOTER CALLOUT ***************/}
    <FooterCallout />
  </Layout>
)
export default AboutPage
